<script>
// import {
//     PlusCircleIcon
// } from '@zhuowenli/vue-feather-icons'
// import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import Axios from "@/config/axios";

export default {
  page: {
    title: "Messages des Contacts",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Messages des Contacts",
      items: [
        {
          text: "Apps",
          href: "/",
        },
        {
          text: "Messages des Contacts",
          active: true,
        },
      ],
      emailsdata: [],
    };
  },
  components: {
    Layout,
    // PlusCircleIcon,
    // Multiselect,
  },
  beforeMount() {
    this.getorders();
  },
  methods: {
    getorders() {
      Axios.get("/admin/contact-messages")
        .then(({ data }) => {
          this.emailsdata = data.data;
        })
        .catch((er) => {
          console.log(er);
        });
    },
  },
  mounted() {
    document.getElementById("emailsdatas").style.display = "none";
    setTimeout(() => {
      document.getElementById("mailLoader").style.display = "none";
      document.getElementById("emailsdatas").style.display = "block";
    }, 1000);
  },
};
</script>

<template>
  <Layout>
    <div class="email-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1">
      <div class="email-content">
        <div class="px-4 pt-4 pb-0">
          <div class="border-bottom border-bottom-dashed">
            <div class="row mt-n2 mb-3 mb-sm-0">
              <div class="col col-sm-auto order-1 d-block d-lg-none">
                <button
                  type="button"
                  class="btn btn-soft-success btn-icon btn-sm fs-16 email-menu-btn"
                >
                  <i class="ri-menu-2-fill align-bottom"></i>
                </button>
              </div>
              <div class="col-sm order-3 order-sm-2">
                <div
                  class="hstack gap-sm-1 align-items-center flex-wrap email-topbar-link"
                >
                  <div class="vr align-self-center mx-2"></div>
                </div>
              </div>
              <div class="col-auto order-2 order-sm-3"></div>
            </div>

            <div class="row align-items-center mt-3">
              <div class="col">
                <ul
                  class="nav nav-tabs nav-tabs-custom nav-primary gap-1 text-center border-bottom-0"
                  role="tablist"
                  id="mailfilter"
                >
                  <li class="nav-item tabtype">
                    <a
                      class="nav-link fw-semibold active"
                      id="primarytab"
                      href="#"
                      @click="changetab('primary')"
                    >
                      <i class="ri-inbox-fill align-bottom d-inline-block"></i>
                      <span class="ms-1 d-none d-sm-inline-block">Message</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-auto">
                <div class="text-muted">{{ emailsdata.length }} messages</div>
              </div>
            </div>
          </div>

          <div id="mailLoader">
            <div class="spinner-border text-primary avatar-sm" role="status">
              <span class="visually-hidden">Chargement...</span>
            </div>
          </div>
          <div
            class="message-list-content mx-n4 px-4 message-list-scroll"
            id="emailsdatas"
            data-simplebar
          >
            <ul class="message-list">
              <div class="accordion accordion-flush">
                <div
                  class="accordion-item border-dashed"
                  v-for="(data, index) of emailsdata"
                  :key="index"
                >
                  <div class="accordion-header">
                    <div class="row">
                      <div class="col-11">
                        <a
                          role="button"
                          class="btn w-100 text-start px-0 bg-transparent shadow-none collapsed"
                          data-bs-toggle="collapse"
                          :href="'#email-collapse' + index"
                          aria-expanded="true"
                          aria-controls="email-collapseTwo"
                        >
                          <div class="d-flex align-items-center text-muted">
                            <!-- <div class="flex-shrink-0 avatar-xs me-3">
                              <img
                                src="@/assets/images//users/avatar-1.jpg"
                                alt=""
                                class="img-fluid rounded-circle"
                              />
                            </div> -->
                            <div class="flex-grow-1 overflow-hidden">
                              <h5 class="fs-14 text-truncate mb-0">
                                {{ data.name }}
                              </h5>
                              <div class="text-truncate fs-12">
                                De: {{ data.email }}
                              </div>
                            </div>
                            <div class="flex-shrink-0 align-self-start">
                              <div class="text-muted fs-12">
                                {{ data.date }}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    :id="'email-collapse' + index"
                    class="accordion-collapse collapse"
                  >
                    <div class="accordion-body text-body px-0">
                      <div v-html="data.message"></div>
                    </div>
                  </div>
                </div>
                <!-- end accordion-item -->
              </div>
              <!-- <li class="bg-transparent text-center">
                <button
                  type="button"
                  class="btn btn-soft-info btn-rounded btn-sm px-2"
                >
                  <i class="mdi mdi-spin mdi-loading me-2"></i>Charger plus
                </button>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <!-- end email-content -->
    </div>
    <!-- Modal -->
    <div
      id="removeItemModal"
      class="modal fade zoomIn"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="btn-close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="mt-2 text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548"
                style="width: 100px; height: 100px"
              ></lord-icon>
              <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>Are you Sure ?</h4>
                <p class="text-muted mx-4 mb-0">
                  Are you Sure You want to Remove this Record ?
                </p>
              </div>
            </div>
            <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                class="btn w-sm btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn w-sm btn-danger"
                id="delete-record"
              >
                Yes, Delete It!
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
    <!-- end modal -->
  </Layout>
</template>
